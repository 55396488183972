<template>
    <div>
      <div
        class="card mb-3"
        style="width: 100%"
        v-loading="loading"
        element-loading-text="Loading..."
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.8)"
      >
        <div class="card-header bg-light">
          <h3 class="title fw-normal mb-0 p-2">My Apps & Credentials</h3>
        </div>
        <el-form ref="form">

            <el-row class="sidemenu" v-if="!isData===true">
                <el-col :span="24">
             <div class="card-body ">
                 <div class="p-4">
                <div class="alert alert-info d-inline-block">
                    <i class="el-icon-info ft"></i>
                    <span class="fs">
                  When you generate a new secret key, you still maintain the original secret.
                 The maximum number of client secrets is two. A client secret is either in enabled or disabled state.
                </span>
                </div><div></div>
                <p>
                    Get started by clicking Generate Key button.
                </p>
              </div>
            </div>
         </el-col>
         <div class="card-footer bg-white">
          <el-form-item class="mb-0 p-2">
            <el-button @click="goToDashboard">Cancel</el-button>
            <el-button
              style="background-color: #409eff; color: #ffffff"
              class="type-2"
              @click="generateNewKey"
              >Generate Key</el-button
            >
          </el-form-item>
        </div>
        </el-row>
      
              
           
        <el-row class="sidemenu" v-if="isData===true">
          <el-col :span="24">
          
            <div class="card-body ">
              <div class="p-4">
                <h5 class="fs-6 text-dark fw-medium">
                Client ID
                </h5>
                <div>
                     <el-input 
                 
           v-on:focus="$event.target.select()" 
           ref="myinput" 
           readonly 
           :value="clientid">
           <el-button slot="append"  @click="copy" icon="el-icon-document-copy"></el-button>
        </el-input>
                </div>
              </div>
            </div>
         </el-col>
        </el-row>
        <el-row class="sidemenu" v-if="isData===true">
          <el-col :span="24">
          
            <div class="card-body ">
              <div class="p-4">
                <h5 class="fs-6 text-dark fw-medium">
                Secret Key
                </h5>
                <div>
                     <el-input 
                 
           v-on:focus="$event.target.select()" 
           ref="myinputkey" 
           readonly 
           :value="secretkey">
           <el-button slot="append"  @click="copykey" icon="el-icon-document-copy"></el-button>
        </el-input>
                </div>
              </div>
            </div>
         </el-col>
        </el-row>
        <el-divider class="m-0"></el-divider>
        
        <el-row class="sidemenu"  v-if="isData===true">
          <el-col :span="24">
            <div class="card-body ">
              <div class="p-4">
                <el-collapse >
  <el-collapse-item title="Secret Key">
    <div>
        <!-- table -->
        <el-table
        :data="data"
          border
          v-loading="loading"
          style="overflow-wrap: normal"
        >
        <el-table-column
            label="Created at"
            width="120"
            prop="created_at"
            sortable="custom"
          >
            <template slot-scope="scope">
              <template v-if="scope.row.created_at">
                <span class="d-inline-block pr-2 text-muted">
                  {{ scope.row.created_at | globalDateTimeFormat }}
                </span>
              </template>
            </template>
          </el-table-column>
          <el-table-column
            label="Client ID"
            width="190"
            prop="clientId"
            sortable="custom"
          >
            <template slot-scope="scope">
              <template v-if="scope.row.clientId">
                <span class="d-inline-block pr-2 text-muted">
                  {{ scope.row.clientId}}
                </span>
              </template>
            </template>
          </el-table-column>

          <el-table-column
            label="Secret Key"
            width="180"
            prop="clientSecret"
            sortable="custom"
          >
            <template slot-scope="scope">
              <template v-if="scope.row.clientSecret">
                <span class="d-inline-block pr-2 text-muted">
                  {{ scope.row.clientSecret}}
                </span>
              </template>
            </template>
          </el-table-column>

          
          <el-table-column
            label="Status"
            width="200"
            prop="isActive"
         
            sortable="custom"
          >
            <template slot-scope="scope">
                <span
                  v-if="scope && scope.row && scope.row.isActive"
                  class="circle"
                >
                  Enable
                </span>
                <span
                 v-else
                  class=" circleone"
                >
                  Disable
                </span>
          
            </template>
          </el-table-column>

       
        </el-table>
        <el-pagination
        class="custom-pagination"
        :current-page.sync="currentPage"
        :page-size="pageSize"
        layout="prev, pager, next, jumper, sizes, total"
        :total="total"
      ></el-pagination>
   
    </div>
    

    <div class="card-footer bg-white">
          <el-form-item class="mb-0 p-2">
            <el-button @click="goToDashboard">Cancel</el-button>
            <el-button
              style="background-color: #409eff; color: #ffffff"
              class="type-2"
              @click="generateNew"
              >Generate New Key</el-button
            >
          </el-form-item>
        </div>
</el-collapse-item>
 
 
</el-collapse>
               
                <el-divider class="m-0"></el-divider>
             
              </div>
            </div>
         </el-col>
        </el-row>
  
      </el-form>
      </div>
    </div>
  </template>
  <script>
import { mapGetters } from "vuex";
  export default {
    name: "DocumentNotifications",
    data() {
      return {
        total: 0,
      currentPage: 1,
      pageSize: 0,
        filterText: "",
        loading: false,
        data: [],
        isData: false,
    };
      
    },
    computed: {
    ...mapGetters("settings", [
      "getClientKeys",
      "getGenerateClientKeys",
      "getfetchGenerateClientKeys",
      "getReGenerateClientKeys"
     
    ]),

    },
    async mounted() {
      this.loading = true;
      this.GetClientKeys();
    //   this.fetchGenerateClientKeys();
      this.loading = false;
    },
    methods: {
        async generateNewKey(){
            this.loading = true;
            await this.$store.dispatch("settings/generateClientKeys");
            console.log("getGenerateClientKeys",this.getGenerateClientKeys);
            this.GetClientKeys();
            this.loading = false;
        },
        async generateNew(){
            this.loading = true;
            await this.$store.dispatch("settings/RegenerateClientKeys");
            console.log("getReGenerateClientKeys",this.getReGenerateClientKeys);
            this.GetClientKeys();
            this.loading = false;
        },
        
        async GetClientKeys() {
      try {
        this.loading = true;
        await this.$store.dispatch("settings/fetchClientKeys");
        console.log("this.getClientKeys.data",this.getClientKeys.data);
        if (this.getClientKeys) {
             this.data = this.getClientKeys.data;
        this.total = this.getClientKeys.data.length || 0;
        if(this.getClientKeys && this.getClientKeys.data && this.getClientKeys.data[0] && this.getClientKeys.data[0].isActive){
            this.isData = true;
          this.clientid=this.getClientKeys.data[0].clientId,
          this.secretkey=this.getClientKeys.data[0].clientSecret
        }

      } else {
        this.loading = false;
      }
        this.loading = false;
      }
      catch (err) {
        console.log(err);
      }
    },

        copy() {
      this.$refs.myinput.focus();
      document.execCommand('copy');
    },
    copykey() {
      this.$refs.myinputkey.focus();
      document.execCommand('copy');
    },
    goToDashboard() {
      this.$router.push({ path: "/" });
    },

    },
    
  };
  </script>
  <style lang="scss" scoped>
    .circle {
    position: relative;
    padding-left: 20px;
    text-transform: capitalize;
    font-size: 14px;

    &::after {
      background: #08ad36;
      content: "";
      position: absolute;
      top: 4px;
      left: 0;
      height: 10px;
      width: 10px;
      display: inline-block;
      border-radius: 50%;
    }
  }
  .circleone{

    position: relative;
    padding-left: 20px;
    text-transform: capitalize;
    font-size: 14px;

    &::after {
      background: #f03232;
      content: "";
      position: absolute;
      top: 4px;
      left: 0;
      height: 10px;
      width: 10px;
      display: inline-block;
      border-radius: 50%;
    }
  }
  .fs{
    font-size: 85%;
  }
  .ft{
    font-size: 150%;
  }
</style>
  